import logo from './logo.svg';
import './App.css';
import BuildATitus from './pages/BuildATitus';

function App() {
  return (
    <BuildATitus />
  );
}

export default App;
